<template>
	<div class="order_statistics">
		<div class="search_content">
			时间选择：
			<div class="mode">
				<div class="mode_item" :class="active_mode == 0 ? 'active' : ''" @click="changeDate(0)">近7天</div>
				<div class="mode_item" :class="active_mode == 1 ? 'active' : ''" @click="changeDate(1)">近30天</div>
				<div class="mode_item" :class="active_mode == 3 ? 'active' : ''" @click="chooseTime()">自定义
					<el-date-picker ref="timePick" @change="changeTimeLimit" v-model="date_limit" type="daterange"  value-format="timestamp" clear-icon>
					</el-date-picker>
				</div>
			</div>
		</div>
		<div class="contents">
			<div class="item">
				<div class="num">{{stat_total.order_total || 0}}</div>
				<div class="desc">订单金额</div>
			</div>
			<div class="item">
				<div class="num">{{stat_total.order_pay_count || 0}}</div>
				<div class="desc">订单数</div>
			</div>
			<div class="item">
				<div class="num">{{stat_total.goods_pay_count || 0}}</div>
				<div class="desc">订单商品</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { StatTotal } from '@/api/statistics.js';
	export default {
		data() {
			return {
				start_time:'',
				end_time:'',
				date_limit:'',
				stat_total:{},
				active_mode:0,
				show_time1:'',
				show_time2:'',
			}
		},
		created() {
			this.getTime();
		},
		methods: {
			changeDate(mode){
				this.active_mode = mode;
				this.getTime();
			},
			changeTimeLimit(e){
				if(e){
					this.start_time = e[0]/1000;
					this.end_time = e[1]/1000;
					this.show_time1 = this.$util.timeStampTurnTime(this.start_time).split(' ')[0]
					this.show_time2 = this.$util.timeStampTurnTime(this.end_time).split(' ')[0]
					this.getStatTotal();
				}
			},
			getTime(){
				if(this.active_mode == 0){
					var curTime = parseInt(new Date().getTime() / 1000);
					var startDate = parseInt(curTime - (6 * 3600 * 24));
					this.start_time = startDate;
					this.end_time = curTime;
					this.show_time2 = this.$util.timeStampTurnTime(curTime).split(' ')[0];
					this.show_time1 = this.$util.timeStampTurnTime(startDate).split(' ')[0];
				}else{
					var curTime = parseInt(new Date().getTime() / 1000);
					var startDate = parseInt(curTime - (30 * 3600 * 24));
					this.start_time = startDate;
					this.end_time = curTime;
					this.show_time2 = this.$util.timeStampTurnTime(curTime).split(' ')[0];
					this.show_time1 = this.$util.timeStampTurnTime(startDate).split(' ')[0];
				}
				this.getStatTotal();
			},
			chooseTime(){
				this.active_mode = 3;
				if (this.$refs.timePick.$el) this.$refs.timePick.$el.click();
			},
			getStatTotal() {
				StatTotal({
					start_time:this.start_time,
					end_time:this.end_time+86399,
				})
				.then(res=>{
					if(res.code>=0){
						this.stat_total = res.data;
					}
				})
			}
		},
	}
</script>

<style lang="scss">
	.order_statistics{
		background-color: #fff;
		padding: 18px 25px 18px 18px;
		.tit{
			color: #333;
			font-size: 15px;
			line-height: 20px;
			margin-bottom: 18px;
		}
		.contents{
			display: flex;
			align-items: center;
			padding: 32px 32px 31px 18px;
			.item{
				flex: 1;
				display: flex;
				align-items: center;
				flex-direction: column;
				padding: 14px 0;
				background-color: #F7F7F7;
				margin-right: 24px;
				.num{
					color: #00A98F;
					font-size: 26px;
					line-height: 36px;
					margin-bottom: 2px;
				}
				.desc{
					color: #333;
					font-size: 12px;
					line-height: 16px;
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
		.search_content{
			display: flex;
			align-items: center;
			.mode{
				display: flex;
				align-items: center;
				margin-left: 15px;
				.mode_item{
					min-width: 71px;
					box-sizing: border-box;
					text-align: center;
					cursor: pointer;
					font-size: 14px;
					line-height: 16px;
					padding: 6px 10px;
					border: 1px solid #E0E0E0;
					&:first-child,
					&:nth-child(2){
						border-right: none;
					}
					&.active{
						border-color: #00A98F;
						background-color: #00A98F;
						color: #fff;
					}
				}
			}
			
			.mode_item{
				position: relative;
				.el-date-editor{
					position: absolute;
					bottom: 0;
					border-radius: 0;
					height: 0;
					width: 0;
					padding: 0;
					border:none;
					.el-range__icon{
						display: none;
					}
					.el-range-separator{
						display: none;
					}
					.el-range-separator{
						line-height: 20px;
					}
					.el-input__icon{
						line-height: 1;
					}
					.el-range-input{
						border: none;
						color: #333;
						font-size: 14px;
						line-height: 20px;
						padding: 0;
					}
				}
				input{
					border: 1px solid #E0E0E0;
					outline: none;
					color: #333;
					font-size: 14px;
					line-height: 20px;
					padding: 8px 12px;
					margin-right: 12px;
				}
				input::placeholder{
					color: #888;
					font-size: 14px;
					line-height: 20px;
				}
			}
			
			.btn{
				color: #FFF;
				font-size: 12px;
				line-height: 16px;
				padding: 6px 18px;
				border-radius: 2px;
				background-color: #00A98F;
				margin-left: 15px;
				cursor: pointer;
			}
		}
		.data_show{
			width: 100%;
			height: 500px;
			.order_stat{
				height: 100%;
			}
		}
	}
</style>